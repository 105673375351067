import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default function createStore() {
	return new Vuex.Store({
		state: {
			host: '',
			books: [],
			parts: [],
			author: {},
			// deviceType: 'pc',
			deviceType: 'mobile',
			showDebug: false,
			ads: {},
			adPosConfig: {
				homeInsert: [6, 24],
				detailInsert: [2, 7, 12],
			},
			adsensConfig: {
				scriptUrl: 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-4688525518274277',
				home_1: { class: 'adsbygoogle', style: 'display:block', 'data-ad-client': 'ca-pub-4688525518274277', 'data-ad-slot': '1870648774', 'data-ad-format': 'auto', 'data-full-width-responsive': 'true' },
				home_insert: { class: 'adsbygoogle', style: 'display:block', 'data-ad-client': 'ca-pub-4688525518274277', 'data-ad-slot': '9170554297', 'data-ad-format': 'auto', 'data-full-width-responsive': 'true' },
				home_2: { class: 'adsbygoogle', style: 'display:block', 'data-ad-client': 'ca-pub-4688525518274277', 'data-ad-slot': '1870648774', 'data-ad-format': 'auto', 'data-full-width-responsive': 'true' },
				detail_1: { class: 'adsbygoogle', style: 'display:block', 'data-ad-client': 'ca-pub-4688525518274277', 'data-ad-slot': '1870648774', 'data-ad-format': 'auto', 'data-full-width-responsive': 'true' },
				detail_2: { class: 'adsbygoogle', style: 'display:block', 'data-ad-client': 'ca-pub-4688525518274277', 'data-ad-slot': '1870648774', 'data-ad-format': 'auto', 'data-full-width-responsive': 'true' },
			},
			domainConfig: {
				icon: '/icon/45.png',
				designType: 1,
				IAmTitle: 'The Timeless Classics of Shakespeare',
				IAMEMAIL: 'shakespearereads.support@gmail.com',
				IAmURL: 'shakespearereads.com',
				Type: 'shakespeare',
				ABOUTUS: 'Explore the timeless genius of Shakespeare with us, where his plays and poetry continue to inspire and captivate.',
				meta: {
					title: 'Complete Works of Shakespeare - Timeless Plays and Sonnets',
					des: 'Explore the timeless works of William Shakespeare, featuring his most celebrated plays and sonnets. Dive into the world of drama, romance, and tragedy.',
				},
				home: {
					title: 'The Timeless Classics of Shakespeare',
					des: 'Explore the timeless works of William Shakespeare, featuring his most celebrated plays and sonnets. Dive into the world of drama, romance, and tragedy.',
				},
				what: {
					title: 'What is shakespearereads.com?',
					des: 'This website is a comprehensive resource dedicated to the timeless works of William Shakespeare. It offers full access to his plays and sonnets, along with detailed literary analyses and historical context. Readers can explore thematic discussions and character studies, making it an essential hub for students and enthusiasts of Shakespearean literature.',
				},
				how: {
					title: 'How to use shakespearereads.com?',
					des: 'Dive into the complete works of Shakespeare, including his plays and sonnets. Access in-depth literary analyses and character studies to enhance your understanding of his timeless themes and language.',
				},
				firebase: {
					apiKey: 'AIzaSyDf03io2XAwOIBNsXwWLzi6RQLZJnhZPY0',
					authDomain: 'webs-907f2.firebaseapp.com',
					projectId: 'webs-907f2',
					storageBucket: 'webs-907f2.firebasestorage.app',
					messagingSenderId: '760918855991',
					appId: '1:760918855991:web:97054c227cd2de436ecab2',
					measurementId: 'G-3BD5PGSNRW',
				},
				ads: 'google.com, pub-7480403109429724, DIRECT, f08c47fec0942fa0',
				adsense: {
					scriptUrl: 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-7480403109429724',
				},
			},
		},
		mutations: {
			setHost(state, host) {
				state.host = host
			},
			setTitle(state, title) {
				state.title = title
			},
			setDomain(state, domainConfig) {
				state.domainConfig = domainConfig
			},
			setDeviceType(state, deviceType) {
				state.deviceType = deviceType
			},
			setDebug(state, showDebug) {
				state.showDebug = showDebug
			},
			setAdsense(state, adsenseConfig) {
				state.adsensConfig = adsenseConfig
			},
			setBooks(state, books) {
				state.books = books
			},
			SET_PARTS(state, parts) {
				state.parts = parts
			},
			SET_AUTHOR(state, author) {
				state.author = author
			},
		},
		getters: {
			showDebug: (state) => state.showDebug,
			deviceType: (state) => state.deviceType,
			host: (state) => state.host,
			adsensConfig: (state) => state.adsensConfig,
			adPosConfig: (state) => state.adPosConfig,
			domainConfig: (state) => state.domainConfig,
			getParts: (state) => state.parts,
			getAuthor: (state) => state.author,
		},
	})
}
